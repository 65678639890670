// Object Master
var Master = function () {
  	this.start = function () {
		$('[data-toggle="sidebar"]').click(function () {
		    $($(this).data("target")).toggleClass('toggled');
		});

		$('.description-fiche dt').click(function () {
		    $(this).toggleClass('open');
		});

		 $('[data-toggle="tooltip"]').tooltip();

		 $('.t-item .openable').click(function () {
		 	$(this).toggleClass('open');

		});

		 $('.openable-item').click(function (event) {
		 	$( event.target ).closest(".section-openable").find(".t-item-title.openable").addClass('open');

		});

		$('a.link-top-card').click(function(e){
			if($(window).width() < 992){
				e.preventDefault();
		 	}
		});

		$('.top-card-titre').click(function(e){
			if($(window).width() < 992){
				$($(this)).toggleClass('active');
		 	}

		});


		$(".all-sections .secteur").on("click", function(e){

	     	if ($(window).width() >= 992) {
				e.preventDefault();
		     	var box = $(this);
		     	var hash = "secteur-" + box.attr('id');
		     	addHash(hash);
	     		$(".all-sections .cadre-secteur").each(function(){
     				$(this).removeClass("hidden");
	     		});
	     		box.parent().animate({opacity: 0}, 1000, function(){
	     			$(this).addClass("hidden");
	     			$(this).css("opacity", "1");
	     		});
		     	var div = $('<div>').css({
		     		"background" : "#e3e3e3",
		     		"z-index" : 10,
		     		"position" : "absolute",
		     		"height" : $(this).height(),
		     		"width" : $(this).width(),
		     		"top" : $(this).offset().top,
		     		"left" : $(this).offset().left,
		     	});
		     	div.appendTo(".all-sections");

		     	var header = $("#section-header-box");
		     	header.css("display", "block");
		     	header.animate({height: $(this).height(), width: "100%"}, 500, function(){});
		     	div.animate({
		     		top: header.offset().top,
		     		left: header.offset().left,},
		     		500, function() {
	     				div.animate({height: header.height(), width: header.width()}, 400, function(){
	     					header.css("opacity", "0");
	     					header.html(box.html());
	     					$("#section-header-box img").attr('src', $("#section-header-box img").data('high'));
	     					div.remove();
	     					header.css("height", "auto");
	     					header.animate({"opacity":"1"}, 700);
	 				});
		     	});
		     }
 	    });

		// $( window ).load(function() {
		//   	var url = window.location.href;
		//   	var tab = url.split('#');
		//   	var anchor = tab[1];
		//   	var selector = "#"+anchor;
		//   	$(selector).addClass("open");

		// });

		var addHash = function(hash){
			document.location.hash = "#" + hash;
		};

		$('#demande-documentation').on('submit', function(e){
			e.preventDefault();
			var form = $(this);
			loader(form);
			var inputs = form.serialize();

			$.post(form.attr('action'), inputs, function(response) {
		        if (response.status == "success") {
		        	form.append("<iframe src="+response.content.conversion_url+" class=\"hidden\"></iframe>");
		        	form.append(createSuccessHtml(form.attr('id'), "Votre formulaire a été envoyé avec succès !"));
		        	deleteError("#demande-documentation");
		        	setTimeout(function(){ $("#success-submit-"+form.attr('id')).remove(); }, 5000);

		        } else if (response.status == "error") {
		        	deleteError("#demande-documentation");
		        	var champs = response.content.form.champs;
		          	for (var i in champs) {
		          		if( champs[i].error != undefined ){
		          			$("[name="+champs[i].name+"]").parent().append(createErrorHtml(champs[i].error));
		          		}
		          	}
		        }
		        loader(form, "hide");
	      	});
		});

		var loader = function(form, action, msg){
			action = (typeof action != 'undefined') ? action : "show";
			msg = (typeof msg != 'undefined') ? msg : "Votre requête est en cours d'acheminement";
			var load = form.find("#loader-"+form.attr('id'));
			if(action == "show"){
				if(load.length == 0){
					var element = createLoaderHtml(form.attr('id'), msg);
					form.append(element);
				}
			} else if(action == "hide"){
				$("#loader-" + form.attr('id')).remove();
			}
		};

		var deleteError = function(parent){
			$(parent).find('.error').remove();
			$(parent).find('.error-cross').remove();
		};

		var createLoaderHtml = function(id, msg){
			return "<div class=\"loader form-process\" id=\"loader-"+id+"\"><div class=\"content\"><i class=\"icon-cycle spinner\"></i><p>"+msg+"</p></div></div>";
		};

		var createSuccessHtml = function(id, msg){
			return "<div class=\"success-submit form-process\" id=\"success-submit-"+id+"\"><div class=\"content\"><i class=\"icon-check\"></i><p>"+msg+"</p></div></div>";
		};


		var createErrorHtml = function(msg){
			return "<span class=\"error-cross\"><i class=\"icon-cross\"></i></span> <span class=\"error\">"+msg+"</span>";
		};
  	};
};
var masterClass = new Master();
